<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-card class="mx-auto my-auto">
                    <v-card-title>
                        Artist
                        <span v-if="activePricePlan && activePricePlan.name" class="ml-4 text-subtitle-1">
                            Your Plan is: {{ activePricePlan.name }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <avatar-edit
                                            v-model="artist.avatar_url"
                                            :enabled="can('haveProfilePhoto', 'artist')"
                                            @error="(m) => this.artistAvatarErr = m"
                                            @file-added="artistAvatarErr = null"
                                        />
                                        <v-alert v-if="artistAvatarErr" type="error">
                                            {{ artistAvatarErr }}
                                        </v-alert>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <subscription-text-input
                                            :loading="artistLoading"
                                            v-model="artist.website_url"
                                            label="Website Url"
                                            ability="haveWebsiteUrl"
                                            resource="artist"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <subscription-text-input
                                            :loading="artistLoading"
                                            v-model="artist.merch_url"
                                            label="Merchandise Url"
                                            ability="haveWebsiteUrl"
                                            resource="artist"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-form v-model="valid" ref="form">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    :loading="artistLoading"
                                                    v-model="artist.name"
                                                    label="Stage name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    :loading="artistLoading"
                                                    v-model="artist.subtitle"
                                                    label="Subtitle"
                                                    placeholder=""
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-textarea
                                                    :loading="artistLoading"
                                                    v-model="artist.bio"
                                                    label="Bio"
                                                    placeholder=""
                                                    :rules="bioRules"
                                                    :counter="(bioLength !== true) && bioLength"
                                                    :counter-value="counterValue"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-select
                                                    :loading="artistLoading"
                                                    multiple
                                                    :items="genres"
                                                    label="Genres"
                                                    item-text="name"
                                                    item-value="id"
                                                    v-model="artist.genres"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            v-if="errors.length"
                                            class="error--text"
                                        >
                                            <v-col
                                                v-for="(error, index) in errors"
                                                :key="`error-${index}`"
                                                cols="12"
                                            >
                                                {{ error }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn
                                                    :disabled="!valid"
                                                    @click="handleArtistUpdate"
                                                >
                                                    Update
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <v-btn :to="{name: 'artist.public', params: {slug: artist.slug}}">
                                                    <v-icon>mdi-eye</v-icon>
                                                    View
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
        <v-row>
            <v-col>
                <v-card class="mx-auto my-auto">
                    <v-card-title>
                        Contact info
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <avatar-edit
                                            v-model="user.avatar_url"
                                            @error="(m) => this.userAvatarErr = m"
                                            @file-added="userAvatarErr = null"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="userAvatarErr">
                                    <v-col>
                                        <v-alert type="error">
                                            {{ userAvatarErr }}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    :loading="profileLoading"
                                                    v-model="user.first_name"
                                                    label="First name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :loading="profileLoading"
                                                    v-model="user.last_name"
                                                    label="Last name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn @click="handleUpdateProfile">Update</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </dashboard-layout>
</template>

<script>
import AvatarEdit from "@/components/AvatarEdit";
import Artist from "@/models/Artist";
import Genre from "@/models/Genre";
import User from "@/models/User";
import DashboardLayout from "@/layouts/DashboardLayout";
import _ from 'lodash';
import SubscriptionTextInput from "@/components/SubscriptionTextInput";

export default {
    name: "Profile",
    components: {SubscriptionTextInput, DashboardLayout, AvatarEdit},
    data: function () {
        return {
            user: {},
            artist: {},
            genres: [],
            errors: [],
            artistLoading: false,
            profileLoading: false,
            activePricePlan: {},
            bioLength: true,
            bioRules: [this.wordsCountRule],
            counterValue: v => _.words(v).length,
            valid: true,
            artistAvatarErr: null,
            userAvatarErr: null,
        }
    },
    methods: {
        async handleUpdateProfile() {
            this.profileLoading = true

            const user = new User(this.user);
            this.user = await user.save()
            await this.$auth.fetch()

            this.profileLoading = false
        },
        async handleArtistUpdate() {
            this.artistLoading = true

            const artist = new Artist(this.artist);
            try {
                this.artist = await artist.save()
            } catch (error) {
                this.errors = Object.values(error.response.data.errors).flat()
            }
            this.setGenres()

            this.artistLoading = false
        },
        async loadArtist() {
            this.artistLoading = true

            this.artist = await Artist.where('user_id', this.$auth.user().id).include(['genres']).first()
            if (this.artist.genres) this.setGenres()

            this.artistLoading = false
        },
        setGenres() {
            const artistGenres = [];

            for (const genre of this.artist.genres) {
                artistGenres.push(genre.id)
            }

            this.artist.genres = artistGenres
        },
        wordsCountRule(v) {
            return (this.bioLength === true) || (v && _.words(this.artist.bio).length <= this.bioLength) || `Bio must be less than ${this.bioLength} words`
        }
    },
    async mounted() {
        this.user = this.$auth.user()
        await this.loadArtist()
        this.genres = await Genre.get()
        this.$refs.form.validate()
    }
}
</script>

<style scoped>

</style>
