<template>
    <v-tooltip top :disabled="enabled">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <vue-dropzone
                    ref="dropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-success="handleSuccess"
                    @vdropzone-mounted="checkStatus"
                    @vdropzone-error="handleError"
                    @vdropzone-file-added="$emit('file-added')"
                />
            </div>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "avatar-edit",
    components: {
        vueDropzone: vue2Dropzone
    },
    props: {
        value: {
            type: String
        },
        enabled: {
            type: Boolean,
            default: () => true
        },
        tooltipText: {
            type: String,
            default: () => "Looks like you want an upgrade (we don't blame you) - this feature is available on a paid plan!"
        }
    },
    data: function () {
        return {
            dropzoneOptions: {
                url: '/api/upload',
                thumbnailWidth: 250,
                maxFilesize: 5,
                acceptedFiles: "image/*",
                headers: {
                    Authorization: null
                },
                enabled: this.enabled
            }
        }
    },
    methods: {
        handleSuccess(file, res) {
            this.$emit('input', res.url);
        },
        checkStatus() {
            if (!this.enabled) {
                this.$refs.dropzone.disable()
            }
        },
        handleError(file, message) {
            this.$refs.dropzone.removeFile(file)
            this.$emit('error', message, file)
        },
    },
    watch: {
        value() {
            if (!this.value) {
                this.$refs.dropzone.removeAllFiles();
                return
            }

            this.$refs.dropzone.removeAllFiles();
            let file = {
                size: 123,
                name: this.value,
                url: this.value,
                thumbnail: this.value
            };
            this.$refs.dropzone.manuallyAddFile(file, this.value);
        },
    },
    async mounted() {
        const token = localStorage.getItem('auth_token_default')
        this.dropzoneOptions.headers.Authorization = "Bearer " + token
    }
}
</script>

<style scoped>
.dz-clickable.dz-started {
    text-align: center;
}
</style>
