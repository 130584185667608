<template>
    <v-tooltip top :disabled="can(ability, resource)">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <v-text-field
                    v-bind="$attrs"
                    :disabled="!can(ability, resource)"
                    v-model="inner_value"
                    :error-messages="errorMessages"
                />
            </div>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "SubscriptionTextInput",
    props: {
        value: {
            type: [Number, String]
        },
        errorMessages: {
            type: Array,
            default: () => []
        },
        resource: {
            type: String,
            required: true
        },
        ability: {
            type: String,
            required: true
        },
        tooltipText: {
            type: String,
            default: () => "Looks like you want an upgrade (we don't blame you) - this feature is available on a paid plan!"
        }
    },
    data() {
        return {
            inner_value: null,
        }
    },
    watch: {
        value: function (val) {
            this.inner_value = val
        },

        inner_value: function (val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped>

</style>
